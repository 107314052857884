@import 'global-fonts';
@import 'global-theme';
@import 'shared-ui';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  overscroll-behavior-y: none; // sadly this does not work in Safari atm :/
}

body {
  @include global-theme();
  background: $ranked-background-color-light;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
